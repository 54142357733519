<template>
<div style="margin-top: 80px;">
    <div class="page-breadcrumb">
        <div class="col-12">
            <div class="row">
                <div class="col-7 col-md-7 col-lg-8 align-self-center">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">dashboard</h3>
                    <div class="d-flex align-items-center ip-none-mobi">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0 p-0">
                                <li class="breadcrumb-item"><a href="index.html">seu último acesso 12/JUL/21 21:06:21</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-5 col-md-5 col-lg-4 align-self-center d-none">
                    <div class="customize-input float-right">
                        <select class="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius">
                            <option selected>add</option>
                            <option value="1">imóvel</option>
                            <option value="1">cliente</option>
                            <option value="1">síndico</option>
                            <option value="1">técnico</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-md-6 col-lg-3 pr-0 order-2 order-md-1">
                            <div class="card border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <div class="d-inline-flex align-items-center">
                                                <h2 class="text-dark mb-1 font-weight-medium">{{ totalEnterprise }}</h2>
                                            </div>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">imóveis</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="opacity-7 text-muted"><i data-feather="map-pin"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 pr-lg-0 order-3 order-md-2">
                            <div class="card border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <div class="d-inline-flex align-items-center">
                                                <h2 class="text-dark mb-1 font-weight-medium">{{ totalClient }}</h2>
                                                <!--<span class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none mb-2">+18.33%</span>-->
                                            </div>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">clientes</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0 d-none d-lg-block">
                                            <span class="opacity-7 text-muted"><i data-feather="star"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 pr-0 order-4 order-md-3">
                            <div class="card border-right card-bg-dash">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">0</h2>
                                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">corretores</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="opacity-7 text-muted"><i data-feather="user-check"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6 col-lg-3 order-5 order-md-4">
                            <div class="card" style="background-color: #212121 !important;">
                                <div class="card-body">
                                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                                        <div class="">
                                            <h2 class="text-white mb-1 font-weight-medium">0</h2>
                                            <h6 class="font-weight-normal mb-0 w-100 text-white" style="color: #ffffff;">integrações</h6>
                                        </div>
                                        <div class="ml-auto mt-md-3 mt-lg-0">
                                            <span class="text-muted"><i data-feather="globe" class="" style="color: #02b475;"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 order-1 order-md-6">
                            <div class="card" style="background-color: transparent !important;">
                                <div class="card-body px-3 pt-box-imoveis">
                                    <h4 class="card-title">imóveis</h4>
                                    <div id="campaign-v2" class="mt-2" style="height: 280px; width: 100%;"></div>
                                    <ul class="list-style-none mb-0">
                                        <li v-for="item in groups_names" :key="item.name">
                                            <i class="fas fa-circle font-10 mr-2" :style="{'color': item.color}"></i>
                                            <span class="text-muted">{{ item.name }}</span>
                                            <span class="text-dark float-right font-weight-medium">{{ item.total }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6 order-6 order-md-5">
                            <div class="card card-bg-dash">
                                <div class="card-body">
                                    <h4 class="card-title">vendas</h4>
                                    <p class="font-14 mb-2 text-muted" style="padding-left: 11px">kit acabamentos</p>
                                    <div class="net-income mt-4 position-relative" style="height: 294px;"></div>
                                    <ul class="list-inline text-center mt-5 mb-2">
                                        <li class="list-inline-item text-muted font-italic">resumo financeiro</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 col-lg-4 d-flex">
                    <BaseBannerDashboard />
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-8">
                    <div class="card card-bg-dash">
                        <div class="card-body">
                            <div class="d-flex align-items-start">
                                <div class="">
                                    <h4 class="card-title mb-0">evolução da obra</h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dropdown sub-dropdown">
                                        <button class="btn btn-link text-muted dropdown-toggle" type="button" id="dd1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i data-feather="more-vertical"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dd1">
                                            <a class="dropdown-item" href="#">ver todas</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-4 mb-5">
                                <div class="stats ct-charts position-relative" style="height: 315px;"></div>
                            </div>
                            <ul class="list-inline text-center mt-4 mb-0">
                                <li class="list-inline-item text-muted font-italic">todos empreendimentos em construção</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4 pl-lg-0">
                    <div class="card card-bg-dash">
                        <div class="card-body">
                            <h4 class="card-title">atividades recentes </h4>
                            <div class="px-4 atividades" style="overflow: scroll; height:389px">
                                <div class="mt-4 activity">
                                    
                                </div>
                            </div>
                            <div class="col-12 text-right">
                                <a href="javascript:void(0)" class="font-14 border-bottom pb-1 border-info">ver todas</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body p-0">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h4 class="card-title mb-0">assistência técnica</h4>
                                    <p class="font-14 mb-0 text-muted" style="padding-left: 11px">últimos chamados</p>
                                </div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="card-list" v-for="item in reports" :key="item.id"> 
                                    <div class="card-body-list"> 
                                        <div class="col-12 p-0 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                                        <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                                    </div>
                                                    <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                        <img v-if="item.client.image" :src="item.client.image" alt="user" class="rounded-circle card-body-list-img" />
                                                    </div>
                                                    <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                    <h6 class="text-truncate cod"><span>CÓD. TICKET:</span> {{ item.id }}</h6>
                                                                    <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{ item.enterprise.name }}</h6>
                                                                    <div class="progress barra-progress">
                                                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 30%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list">
                                                                    <h6 class="text-truncate card-body-list-title" v-if="item.client">{{ item.client.first_name}} {{ item.client.last_name }}</h6>
                                                                    <h6 class="text-truncate cpf d-none d-lg-block" v-if="item.client">{{ item.client.cpf }}</h6>
                                                                </div>
                                                                <div class="col-auto col-md-auto col-lg-1 align-self-center pad-content-list">
                                                                    <h6 class="text-truncate torre">{{ item.tower }}</h6>
                                                                </div>
                                                                <div class="col-8 col-md-4 col-lg-2 align-self-center pad-content-list">
                                                                    <h6 class="text-truncate apto">{{ item.apt_number}}</h6>
                                                                </div>
                                                                <div class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                                    <h6 class="text-truncate empreendi">{{ item.subject }}</h6>
                                                                    <h6 class="text-truncate cod-tec">Téc.: <span v-if="item.technical">{{ item.technical.first_name }} {{ item.technical.last_name }}</span></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                                        <b-link :to="{name: 'ReportEdit', params: {id: item.id}}" class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" href="?app=assistenciatecnica/edit-chamado.php">+</b-link>
                                                        <a class="btn btn-dark rounded-circle btn-circle font-20 ml-2" data-bs-toggle="modal" data-bs-target="#right-modal" href="javascript:void(0)" @click="id = item.id"><i class="fas fa-eye"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-center" style="margin-bottom: 20px;">
                                <a href="?app=assistenciatecnica/chamados.php" class="font-20 a-link-1"><i class="icon-options"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Qsuíte</h4>
                            <div class="row mb-3 align-items-center mt-1 mt-3">
                                <div class="col-4 text-right">
                                    <span class="text-muted font-14">RD Station</span>
                                </div>
                                <div class="col-5">
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-3 text-right">
                                    <span class="mb-0 font-14 text-dark font-weight-medium">0%</span>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-4 text-right">
                                    <span class="text-muted font-14">Bitrix24</span>
                                </div>
                                <div class="col-5">
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar bg-danger" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="80"></div>
                                    </div>
                                </div>
                                <div class="col-3 text-right">
                                    <span class="mb-0 font-14 text-dark font-weight-medium">0%</span>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-4 text-right">
                                    <span class="text-muted font-14">Caps</span>
                                </div>
                                <div class="col-5">
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar bg-cyan" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-3 text-right">
                                    <span class="mb-0 font-14 text-dark font-weight-medium">0%</span>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-4 text-right">
                                    <span class="text-muted font-14">Whatsapp</span>
                                </div>
                                <div class="col-5">
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar bg-cyan" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-3 text-right">
                                    <span class="mb-0 font-14 text-dark font-weight-medium">0%</span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <span class="text-muted font-14">Uau</span>
                                </div>
                                <div class="col-5">
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-3 text-right">
                                    <span class="mb-0 font-14 text-dark font-weight-medium">0%</span>
                                </div>
                            </div>
                            <ul class="list-inline text-center mt-4">
                                <li class="list-inline-item text-muted font-italic">status para novas integrações</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-8 d-flex pl-lg-0">
                    <BaseSuggestionsDashboard />
                </div>
            </div>
        </div>
    </div>

    <ViewReport :id="id" />
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ClientService from "@/services/resources/ClientService";
import EnterpriseService from "@/services/resources/EnterpriseService";
import OrderService from "@/services/resources/OrderService";
import ViewReport from '@/components/Reports/ViewReport';
import moment from 'moment';
const service = ReportService.build();
const serviceClient = ClientService.build();
const serviceEnterprise = EnterpriseService.build();
const serviceOrder = OrderService.build();

export default {
    components:{
      ViewReport
    },
    data(){
        return{
            reports: [],
            groups_names: [],
            sales_group: [],
            id: 0,
            totalClient: 0,
            totalEnterprise: 0
        }
    },
    methods:{
        fetchReports(){

            let data = {
                page: 1,
                per_page: 5
            }

            service
            .search(data)
            .then(resp => {
                this.reports = resp.data;
            })
            .catch(err => {
                console.log(err)
            })
        },
        fetchClients(){

            let data = {
                page: 1,
                per_page: 5
            }

            serviceClient
            .search(data)
            .then(resp => {
                this.totalClient = resp.total;
            })
            .catch(err => {
                console.log(err)
            })
        },
        fetchEnterprises(){


            serviceEnterprise
            .search()
            .then(resp => {
                this.totalEnterprise = resp.length;
                let groups = resp.reduce(function (r, a) {
                            r[a.status.name] = r[a.status.name] || [];
                            r[a.status.name].push(a);
                            return r;
                        },Object.create(null));
                let keys = Object.keys(groups);
                for(var item in keys){
                    this.groups_names.push({
                        name: keys[item],
                        color: groups[keys[item]][0].status.color,
                        total: groups[keys[item]].length
                    });
                }
                this.makeChart();

            })
            .catch(err => {
                console.log(err)
            })
        },
        makeChart(){
            c3.generate({
                bindto: '#campaign-v2',
                data: {
                    columns: this.groups_names.map(elem => {
                        return [elem.name, elem.total]
                    }),

                    type: 'donut',
                    tooltip: {
                        show: true
                    }
                },
                donut: {
                    label: {
                        show: false
                    },
                    title: 'status',
                    width: 30
                },

                legend: {
                    hide: true
                },
                color: {
                    pattern: this.groups_names.map(elem => {
                        return elem.color
                    }),
                }
            });
        },
        fetchOrders(){
             serviceOrder
            .search()
            .then(resp => {
                let groups = resp.reduce(function (r, a) {
                            r[moment(a.created_at).format('MMM')] = r[moment(a.created_at).format('MMM')] || [];
                            r[moment(a.created_at).format('MMM')].push(a);
                            return r;
                        },Object.create(null));
                let keys = Object.keys(groups);
                let sales_group = [];
                
                for(var item in keys){
                    sales_group.push(groups[keys[item]].map(elem => elem.price).reduce((partialSum, a) => partialSum + a, 0));
                }
                this.setupSales(sales_group, keys);

            })
            .catch(err => {
                console.log(err)
            })
        },
        setupSales(dataBase, titles){


            var data = {
                labels: titles,
                series: [
                    dataBase
                ]
            };

            var options = {
                axisX: {
                    showGrid: false
                },
                seriesBarDistance: 1,
                chartPadding: {
                    top: 15,
                    right: 15,
                    bottom: 5,
                    left: 0
                },
                plugins: [
                ],
                width: '100%'
            };

            var responsiveOptions = [
                ['screen and (max-width: 640px)', {
                    seriesBarDistance: 5,
                    axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        }
                    }
                }]
            ];
            new Chartist.Bar('.net-income', data, options, responsiveOptions);

        }
    },
    mounted(){
        this.setupSales();
        this.fetchReports();
        this.fetchClients();
        this.fetchEnterprises();
        this.fetchOrders();
    }
}
</script>